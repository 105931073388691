import React from "react"
import { graphql } from "gatsby"
import "../components/queries/fragments-structures"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Charts from "../components/layout/content/charts"

const districtsInfoPage = ( { data: { allItems: { nodes }}} ) => {
  /**
   * 
   * This page is only for generating content what I'll insert into Drupal static pages. It is not linked through web.
   * 
   */
  
  const sortingFields = [
    'field_population_total',
    'field_size_total',
    'field_population_density_total',
    'field_population_avg_age',
    'field_population_index_old_age',
    'field_t_beds',
    'field_t_beds_1y_share',
    'field_t_objects',
    'field_t_objects_1y_share',
    'field_t_overstays',
    'field_t_overstays_1y_share',
    'field_t_tourists',
    'field_t_tourists_1y_share'
  ]
  
  const fullChartPath = '/okresy/zoznam-okresov-'
  const linkName = "Zoznam okresov"

  return (
  <Layout>
      <Seo title="Uvodna stranka" />
      <br /><br /><br />
      <h2>Rebríčky okresov</h2>
      <p>Celkový počet okresov: {nodes.length}</p>
      <br />
      {
        sortingFields.map(item => 
          <Charts arr={nodes} field={item} chartPath={fullChartPath} linkName={linkName} />
      )}
    </Layout>    
  )
}

export default districtsInfoPage

export const query = graphql`
  query {
    allItems: allTaxonomyTermStructure (
      filter: {field_type: {eq: "district"}}
    ) {
            nodes {
                    ...chartsQueries
                }
        }
    }
`
